import React from "react";
import { Layout } from "../components/Layout";

export default function NotFound() {
  return (
    <Layout>
      <h3>Lost?</h3>
    </Layout>
  );
}
